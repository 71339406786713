<template>
  <div>
    <Pane />

    <div class="container">
      <a-form
        :form="form"
        :label-col="{ span: 8 }"
        :wrapper-col="{ span: 12 }"
        :colon="false"
        @submit="handleSubmit"
      >
        <a-row>
          <a-col :lg="12" :md="12" :sm="24">
            <a-form-item label="酒水品名">
              <a-input
                v-decorator="[
                  'name',
                  { rules: [{ required: true, message: '请输入！' }] },
                ]"
              />
            </a-form-item>
          </a-col>

          <a-col :lg="12" :md="12" :sm="24">
            <a-form-item label="库存">
              <a-input-number
                style="width: 100%"
                :min="1"
                v-decorator="[
                  'quantity',
                  { rules: [{ required: true, message: '请输入！' }] },
                ]"
              />
            </a-form-item>
          </a-col>

          <a-col :lg="12" :md="12" :sm="24">
            <a-form-item label="类型">
              <a-select
                @change="onTypeChange"
                v-decorator="[
                  'typeInv',
                  { rules: [{ required: true, message: '请选择！' }] },
                ]"
              >
                <a-select-option
                  v-for="item in typeList"
                  :key="item.value"
                  :value="item.value"
                >
                  {{ item.name }}
                </a-select-option>
              </a-select>
            </a-form-item>
          </a-col>

          <a-col :lg="12" :md="12" :sm="24">
            <a-form-item label="子类型">
              <a-select
                v-decorator="[
                  'typeSub',
                  { rules: [{ required: true, message: '请选择！' }] },
                ]"
              >
                <a-select-option
                  v-for="item in subTypeList"
                  :key="item.value"
                  :value="item.value"
                >
                  {{ item.name }}
                </a-select-option>
              </a-select>
            </a-form-item>
          </a-col>

          <a-col :lg="12" :md="12" :sm="24">
            <a-form-item label="单位">
              <a-select
                v-decorator="[
                  'unit',
                  { rules: [{ required: true, message: '请选择！' }] },
                ]"
              >
                <a-select-option
                  v-for="item in unitList"
                  :key="item.value"
                  :value="item.value"
                >
                  {{ item.name }}
                </a-select-option>
              </a-select>
            </a-form-item>
          </a-col>

          <a-col :lg="12" :md="12" :sm="24">
            <a-form-item label="单价(元)">
              <a-input-number
                :formatter="$inputNumberFormat"
                :min="0"
                v-decorator="[
                  'unitPrice',
                  { rules: [{ required: true, message: '请输入！' }] },
                ]"
                style="width: 100%"
              />
            </a-form-item>
          </a-col>
        </a-row>

        <div class="center">
          <a-space>
            <a-button type="primary" html-type="submit" :loading="loading"
              >保存</a-button
            >
            <a-button @click="$close($route.path)">关闭</a-button>
          </a-space>
        </div>
      </a-form>
    </div>
  </div>
</template>

<script>
import { add } from "@/api/oa/material";
import { mapGetters } from "vuex";
export default {
  name: "addMaterial",

  data() {
    return {
      form: this.$form.createForm(this),
      subTypeList: [], // 子类型
      loading: false,
    };
  },

  computed: {
    ...mapGetters("setting", ["findDataDict"]),
    typeList() {
      return this.findDataDict("invType");
    },
    unitList() {
      return this.findDataDict("invUnit");
    },
  },

  methods: {
    onTypeChange(value) {
      if (value) {
        const obj = this.typeList.find((item) => item.value === value);
        if (obj && Array.isArray(obj.children)) {
          this.subTypeList = obj.children;
        }
      } else {
        this.subTypeList = [];
      }
    },

    handleSubmit(e) {
      e.preventDefault();
      this.form.validateFields((err, values) => {
        if (!err) {
          this.loading = true;

          add({
            ...values,
          })
            .then(() => {
              this.form.resetFields();
            })
            .finally(() => {
              this.loading = false;
            });
        }
      });
    },
  },
};
</script>

<style lang="less" scoped>
.container {
  background-color: #fff;
  padding: 24px 160px;
}

.center {
  margin-top: 80px;
}
</style>
